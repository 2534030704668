
//  TODO:  This is BASICALLY the same pattern as what we have with Halia, AND it's simlar to Components / Blocks / Functions.  We WANT to register a single thing, likely a function (which has criteria for a component / app) and TAG it as an app.

import { CorePluginClass } from "halia";
import * as React from 'react';
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import { SystemHeader } from "../../packages/kelp-bar/system-header";
import { Entity2Plugin, EntityContext, EntityPluginContext, EntityTable } from "./entity-plugin";
import { Hessia2Context, Hessia2Plugin, System } from "./hessia2-plugin";
import { useSizes } from "../gallery/sizes-helper";
import { NounServiceInstanceInternal } from "../../packages/noun-service/noun-service";
import { Entity } from "./entity-service";
import { createStackNavigator } from "@react-navigation/stack";

//  NOTE:  It's the SAME as a "System"... but it's just "tagged" as an app???
export interface App extends System {
}

export interface AppsContext {
  apps: App[];
  installApp: (app: App) => void;
  appEntities: NounServiceInstanceInternal<Entity>[];
}
export const AppPluginContext = React.createContext<Apps2Plugin | undefined>(undefined);
export const AppsContext = React.createContext<AppsContext>({ apps: [], installApp: () => null, appEntities: [] });

const AppStackNav = createStackNavigator();

const AppViewer = ({ appEntity }: { appEntity: NounServiceInstanceInternal<Entity<App>> }) => {

  const entity2 = React.useContext(EntityPluginContext);
  const { apps } = React.useContext(AppsContext);
  const { isDesktop } = useSizes();
  const app = apps.find(_app => entity2?.entityService.generateEntityIdentifier(_app) === appEntity.payload.id);
  const Comp = app?.component;

  return (
    <View style={{ flex: 1, padding: isDesktop ? 30 : 0, backgroundColor: 'white' }}>
      <View style={{ flex: 1, backgroundColor: 'white', borderRadius: isDesktop ? 12 : 0, overflow: 'hidden', borderColor: '#eeeeee', borderWidth: isDesktop ? 2 : 0 }}>
        <Comp />
      </View>
    </View>
  );
}

export const AppsHome = () => {

  const { appEntities } = React.useContext(AppsContext);
  const [selectedApp, selectApp] = React.useState<NounServiceInstanceInternal<Entity<App>> | undefined>();

  const crumbs = [{ text: "Apps", onPress: () => selectApp(undefined) }];
  if (selectedApp) { crumbs.push({ text: selectedApp.payload.name || "Unknown", onPress: () => null }) }

  return (
    <>
      <SystemHeader system={AppSystem} breadcrumbs={true} crumbs={crumbs} />
      {
        !selectedApp && <EntityTable entities={appEntities} onPress={(entity) => selectApp(entity)} />
      }
      {
        selectedApp && <AppViewer appEntity={ selectedApp } />
      }
    </>
  );
}

const AppSystem: System = {
  pinned: false,
  id: "app-system",
  color: "#aaaaaa",
  name: "Apps",
  description: "Apps System",
  emoji: "🔗",
  component: AppsHome,
  icon: { type: 'ionicon', name: 'apps-outline' },
  primaryColor: "#e31b54",
  backgroundColor: "#fff7f9",
}





export class Apps2Plugin extends CorePluginClass {

  public static details = {
    name: "Apps",
    description: "Apps Plugin",
    dependencies: [Hessia2Plugin.details.id, Entity2Plugin.details.id],
    id: "apps"
  }

  protected entity2!: Entity2Plugin;

  public install = async (program: any, { hessia2, entity2 }: { hessia2: Hessia2Plugin, entity2: Entity2Plugin }) => {

    const _me = this;

    this.entity2 = entity2;

    //  TODO:  Register "Apps" as Hessia Entities
    //  NOTE:  I'm choosing NOT to register the Hessia Core systems (like Entities, etc) as "Systems"... but I MAY want to do that at some point, ESPEICALLY because we have Plugins which register pieces!!!  We need to be able to see what they do!

    hessia2.registerHOC(({ children }) => {

      //  NOTE:  An "App" is JUST a React Component.
      //  CONSIDER:  We could allow this to use Hessia Functions which return UI.

      const [apps, setApps] = React.useState<App[]>([]);
      const hessia2Context = React.useContext(Hessia2Context);
      const installApp = (app: App) => {
        setApps(prev => ([...prev, app]));
      }
      React.useEffect(() => {
        hessia2Context?.installSystem(AppSystem, true);
      }, []);

      const [appEntities, setAppEntities] = React.useState<NounServiceInstanceInternal<Entity>[]>([]);

      //  TODO:  We have this SAME logic in a BUNCH of places.  Centralize it!
      const createAppsIfNeeded = async () => {
        const _appEntities: NounServiceInstanceInternal<Entity>[] = [];
        for (const app of apps) {
          const systemEntityId = entity2.entityService.generateEntityIdentifier(app);
          const systemEntity = await entity2.entityService.getEntityNounById(systemEntityId);
          if (!systemEntity) {
            const internalEntity = await entity2.entityService.createEntity({
              name: app.name,
              id: systemEntityId,
              description: app.description,
              owners: [AppSystem]
            });
            _appEntities.push(internalEntity);
          } else {
            _appEntities.push(systemEntity);
          }
        }
        setAppEntities(_appEntities);
      }

      React.useEffect(() => {
        createAppsIfNeeded();
      }, [apps]);


      return (
        <AppPluginContext.Provider value={this}>
          <AppsContext.Provider value={{ apps, installApp, appEntities }}>
            {children}
          </AppsContext.Provider>
        </AppPluginContext.Provider>
      );
    });


    return this;
  }
}

