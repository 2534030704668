import { SDTBinarySerializedSchema } from 'davel/dist/src/parsers/binary';
import { deserializeSDT, HaborComponent, HSDTDerivedSchema, HSDTRelationshipSerializedSchema, SDT, SDTAnySerializedSchema, SDTArraySerializedSchema, SDTBooleanSerializedSchema, SDTDateSerializedSchema, SDTKeywordSerializedSchema, SDTNumberSerializedSchema, SDTObject, SDTObjectSerializedSchema, SDTOptionSerializedSchema, SDTTextSerializedSchema, SDTTypeSerializedSchema } from 'habor-sdk';
import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { IconType } from 'react-native-elements/dist/icons/Icon';
import { RoundIconButton } from '../kelp-bar/buttons';
import { SimpleCard } from '../kelp-bar/simple-card';
import { medSpacer } from '../kelp-bar/styles';
import { TextParagraph, TextSubParagraph } from '../kelp-bar/text';
import { SDTRenderer, SDTRendererParams, SDTViewRenderer, SDTViewRendererParams } from './davel-ui-tools';
import { sdtColorRenderer, sdtHexColorRenderer } from './habor-types/color/color-field';
import { sdtCustomTypeRenderer } from './habor-types/custom-type/custom-type-field';
import { sdtCustomTypeViewer } from './habor-types/custom-type/custom-type-view';
import { sdtNounRenderer } from './habor-types/noun/noun-field';
import { sdtRelationshipRenderer } from './habor-types/relationship/relationship-field';
import { sdtRelationshipViewRenderer } from './habor-types/relationship/relationship-view';
import { sdtAnyRenderer } from './types/any/any-field';
import { sdtArrayRenderer } from './types/array/array-field';
import { sdtArrayViewRenderer } from './types/array/array-view';
import { sdtBinaryRenderer } from './types/binary/binary-field';
import { sdtBooleanRenderer } from './types/boolean/boolean-field';
import { sdtBooleanViewRenderer } from './types/boolean/boolean-view';
import { sdtDateRenderer } from './types/date/date-field';
import { sdtDerivedRenderer } from './types/derived/derived-field';
import { sdtKeywordRenderer } from './types/keyword/keyword-field';
import { keywordTransformer } from './types/keyword/keyword-transformer';
import { sdtNumberRenderer } from './types/number/number-field';
import { sdtObjectRenderer } from './types/object/object-field';
import { sdtObjectTransformer } from './types/object/object-transformer';
import { sdtObjectViewRenderer } from './types/object/object-view';
import { sdtOptionRenderer } from './types/option/option-field';
import { sdtSDTRenderer } from './types/sdt/sdt-field';
import { sdtTransformer } from './types/sdt/sdt-transformer';
import { sdtTextRenderer } from './types/text/text-renderer';
import { sdtIconRenderer } from './habor-types/icon/icon-field';
const uuidv4 = require('uuid/v4');

//  TODO:  Make sure this is de-coupled from Hessia / Habor, etc...

//  DESCRIPTION:  There are three pieces to this file:  Renderers, Transformers, and ViewRenderers.
//    Renderer: Converts from an SDT to a React Element (uses Redux Form).
//    Transformer:  Transforms the value of the rendered form before sending to the server.
//    ViewRenderer:  Renders a Schema / Value pair.

/**
 * Renders a form just for an SDT.  It does not need to be an Object.  To render this, we use the Object renderer.
 */

export const SDTForm = ({ sdt, value, onSubmitFail, onSubmitSuccess, onSubmit, autoSubmit, id, debug, metadata }: { onSubmit: (value: any) => void, sdt: SDT, value?: any, onSubmitSuccess?: () => void, onSubmitFail?: (err: any) => void, autoSubmit?: boolean, id?: string, debug?: boolean, metadata?: any }) => {

  //  CONSIDER:  I'm 99% sure I've already built this.
  //  TODO:  Build an SDT renderer first INSTEAD of an Object renderer first.
  //  TODO:  Decouple Redux Form.

  const wrappedSdtSchema: SDTObject = { type: "object", properties: { value: sdt } }
  return <DavelForm value={{ value }} onSubmit={(value) => onSubmit(value.value)} id={id} metadata={metadata} debug={debug} autoSubmit={autoSubmit} onSubmitFail={onSubmitFail} onSubmitSuccess={onSubmitSuccess} schema={wrappedSdtSchema} />
}

export interface SDTComponentProps extends SDTRendererParams { }
interface SDTComponentState {
  elem?: any;
}
export class SDTComponent extends React.Component<SDTComponentProps, SDTComponentState> {

  constructor(props: SDTComponentProps) {
    super(props);
    this.state = {}
  }
  componentDidMount = async () => {
    // console.log(`SDT Component Mounted: ${this.props.name}`);
    this.refresh();
  }

  public refresh = async () => {

    // console.log(`SDT Component Reloading: ${this.props.name}`);

  


    //  Get the Override Component
    //  TODO:  Add parent context to metadata?  Support Habor Types and UI Overrides with a PLUGIN???  This means we need a registration point for Metadata and for Metadata Processors?  JUST like Middleware I support... But maybe call it Registration Points?
    //  AH!!!  This whole Davel thing is just ONE example of a place where we have this CONTEXT / SETTINGS system!  I think ANY system should be able to use this!!!  COOL!!!
    // const fieldSetting: FieldSettings = getAppliedSettings({ name: "field-setting" });

    // //  Process the Settings
    // if (fieldSetting) {
    //   for (const setting of fieldSetting.selections) {
    //     if (sdt.type == setting.sdtType) {
    //       //  TODO-IMPROTANT:  The "hessia" concept should NOT exist here!
    //       const component = Program.hessia?.getHaborComponent(setting.component);  //  TODO:  This should NOT be in Davel Core!  Use the Plugin pattern to install NEW feature into Davel from other Plugins???  MAYBE instead of using the Plugin pattern (Halia) we can just use an ad-hoc pattern?  I'm REALLY trying to avoid ad-hoc patterns!
    //       const reactElem = (
    //         <DavelField required={sdt.required} key={name} name={name}>
    //           {/* TODO:  changePage and workspace don't really make sense here!  They shuold DEFINITELY not be in Davel Core!  SOMETHING needs to be split it! */}
    //           {/* TODO-CRITICAL:  Make sure the Davel Metadata is passed there required params!  We SHOULD find some way to type it!  Also, remove this code from Davel into Hessia! */}
    //           <Field name={ name } component={(props: any) => <HaborMagicComponentViewer { ...metadata } componentProps={ { ...setting.props, onChange: props.input.onChange, value: props.input.value || setting.props.value } } component={ component } handleClose={ () => null }  /> } />
    //         </DavelField>
    //       )
    //       this.setState({ elem: reactElem });
    //       return;
    //     }
    //   }
    // }

    //  Render
    
  }

  public componentDidUpdate = (prevProps) => {
    // console.log(`SDT Component Updated: ${this.props.name}`);
    if (prevProps !== this.props) {
      this.refresh();
    }
  }

  public componentWillUnmount = () => {
    // console.log(`SDT Component Un-Mounting: ${this.props.name}`);
  }

  public render = () => {

    const { sdt, topLevel, metadata, value, autoSubmit, update, name } = this.props;

    //  Get the Renderer
    // console.log(`Getting renderer for '${ name }' with value '${ JSON.stringify(value) }' and SDT '${ JSON.stringify(sdt) }'`);
    if  (!sdt) {
      return <Text>No SDT Provided for '{ name }' with value '{ JSON.stringify(value) }'</Text>
    }
    const TypeRenderer = getRenderer(sdt.type);
    if (!TypeRenderer) {
      console.log("No Renderer!");
      throw new Error(`No renderer registered for the specified type (${sdt.type}).`);
    }

    return <TypeRenderer { ...{ sdt, update, topLevel, metadata, value, autoSubmit, getSchemaFromType: getSchema, name }} />

    //  TODO-CRITICAL:  I BELIEVE this is breaking state persistence!  Because, we effetively DESTROY / UNMOUNT any existing element that had been rendered at this key.  Then, we mount it again when the state changes.  PERHAPS there's a way to tell react NOT To make a change?  Hmm I THINK the problem is that we're using a component to do this.  IF we just used an async function to construct the element, then we ALWAYS transition from a full state tree to another!? Hhmmm... I THINK that makes sense.  Ah!! So the idea is to ALWAYShave the compnent tree cached in state, and return THAT, this way, we don't have a segue with NO compnent and re-render everything? HM!  AH!  OR perhaps we can store it HERE, and return the PREVIOUS state!?  I THINK that centralizes and makes things easier!
  }
}



export const DavelFormButton = ({ onPress, title, color = '#631dc6' }: { onPress?: (e: any) => any, title: string, color?: string }) => {
  return (
    <TouchableOpacity onPress={onPress} style={{ borderRadius: 5, height: 38, backgroundColor: color, alignItems: 'center', justifyContent: 'center' }}>
      <TextSubParagraph style={{ color: 'white' }}>{title}</TextSubParagraph>
    </TouchableOpacity>
  );
}

interface DavelReduxFormProps {
  onCancel?: () => void;
  autoSubmit?: boolean;
  children?: any;
  // onUpdate: (value: any) => void;
}
//  REFERENCE:  https://codesandbox.io/s/myoynq411j?module=%2FUserForm.tsx
// class DavelReduxForm extends React.Component<InjectedFormProps<any, DavelReduxFormProps> & DavelReduxFormProps> {
//   public render = () => {
//     const { handleSubmit, children, onCancel, autoSubmit } = this.props;
//     return (
//       <View style={{ display: 'flex', flexDirection: 'column' }}>
//         {children}

//         {/* TODO:  Instead of hard-coded buttons, let the user supply callbacks / event hooks and create their OWN buttons?  */}
//         {
//           autoSubmit ? null :
//             <View style={{ backgroundColor: 'gray' }}>
//               <DavelFormButton onPress={handleSubmit} title='SUBMIT' />
//               <DavelFormButton onPress={this.props.onCancel} title='CANCEL' />
//             </View>
//         }
//       </View>
//     );
//   }
// }

export interface DavelFormProps {
  schema: SDTObject;
  value?: any;
  onSubmit: (value: any, schema: SDTObject) => void;
  metadata?: any;
  //  TODO:  Does it make sense to have this here?  Sometimes the concept of cancelation doesn't make sense on the rendered form, for example, when NOT in a modal.
  onCancel?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitFail?: (err: any) => void;
  autoSubmit?: boolean;
  id?: string;
  debug?: boolean;
}

export interface DavelFormState {
  // fields: any;
  value: any;  //  NOTE:  The idea is, each component will hm... one issue is, the component can go off-screen.  Hmm... the idea is we mount them all though.  Then, they hold their own state.  This way each component is a "field", and the field will like... 
  // formId?: string;
}

//  TODO-IMPORTANT:  When an EXTERNAL thing keeps state, then it MIGHT pass in new props which essentially causes a re-render each time!  PERHAPS this is OK, but we should really use the Redux stuff, OR local state... it's getting WICKED confusing with the state in TWO places.


/**
 * Used to select the value for an "SDTObject" Schema
 */
export class DavelForm extends React.Component<DavelFormProps, DavelFormState> {

  constructor(props: DavelFormProps) {
    super(props);
    this.state = {
      value: this.props.value
    }
  }

  public componentWillReceiveProps(nextProps: Readonly<DavelFormProps>, nextContext: any): void {
    if (nextProps != this.props) {
      this.setState({ value: nextProps.value });
    }
  }

  private update = (value: any) => {
    const { schema, autoSubmit, onSubmit } = this.props;
    this.setState({ value });
    if (autoSubmit) {
      onSubmit(value, schema);
    }
  }

  public render = () => {

    const { schema, autoSubmit, metadata, onSubmit, onCancel } = this.props;

    const { value } = this.state;

    return (
      <>

        {
          // Debug Mode
          this.props.debug ?
            <SimpleCard>
              <View style={{ backgroundColor: 'orange', flexDirection: 'row', height: 50, width: '100%' }}>
                <RoundIconButton iconSelection={{ name: "info", type: "material" }} onPress={() => alert(JSON.stringify({ schema, value }))} />
              </View>
              <SDTComponent {...{ sdt: schema, name: 'root', update: this.update, topLevel: true, metadata, value, autoSubmit }} />
            </SimpleCard> :

            //  Regular Mode
            <SDTComponent {...{ sdt: schema, name: 'root', update: this.update, topLevel: true, metadata, value, autoSubmit }} />
        }

        {
          autoSubmit ? null :
            <View style={{ backgroundColor: 'gray' }}>
              <DavelFormButton onPress={() => onSubmit(value, schema)} title='SUBMIT' />
              <DavelFormButton onPress={onCancel} title='CANCEL' />
            </View>
        }

      </>
    )

  }
};

//  Wrap the DavelReduxForm
//  NOTE:  By doing this here we get to define a custom form ID.
//  HEADBASH:  Spent almost 5 hours trying to make the Redux form show the initial values... It turns out I needed to keep the wrapped form
//             defined here in the global context and then pass the form name to the wrapped component.  So, I'd like to understand why this needs to be in the global scope.
//             REFERENCE:  https://stackoverflow.com/questions/40509754/how-do-you-pass-in-a-dynamic-form-name-in-redux-form#40688745
// const WrappedDavelReduxForm = reduxForm<any, DavelReduxFormProps>({
//   // destroyOnUnmount: false,  //  TODO:  This is problematic.. we want to destroy when the PARENT container holding the state is unounted in most cases...  Hmm... maybe not even that.  Perhaps another reason to switch from Redux Form?
//   onChange: (values, dispatch, props: any, previousValues) => {
//     // props.onUpdate(values);
//     if (props.autoSubmit) {
//       console.log("UPDATED REDUX FORM");
//       props.submit();
//     }
//   },
// })(DavelReduxForm);

//  TODO:  Submit the form programatically from the header?
//  TODO:  Support 2 column / multi-column layout?

enum PropType {
  Object, Array, Value
}


//
//  Renderers
//

export const registerDavelType = (type: DavelType) => {
  davelTypeRegister.push(type);
}

//  CONSIDER:  We MAY have SEVERAL maps spread around!  SHOULD be able to LINK them together in a secondary system mm!!  Without forcing a hard-code to just one hm!  Like... LOGICALLY able to access all of those as properties of the same thing hm!  As long as they have the same key mm!
export interface DavelType {
  id: string;
  name: string;
  description?: string;
  color?: string;
  backgroundColor?: string;
  icon: { name: string, type: string };
  renderer: SDTRenderer;
  defaultSDT: SDT;
  defaultValue?: any;
  schema?: SDTObject
}

export const davelTypeRegister: DavelType[] = [

  //  Built-In
  {
    id: "keyword",
    name: "Keyword",
    description: "A small textual identifier.",
    icon: { name: "string", type: "material" },
    renderer: sdtKeywordRenderer,
    defaultSDT: { type: "keyword" },
    defaultValue: "",
    schema: SDTKeywordSerializedSchema //  An SDT Object schema that encodes the properties for the serialized form of this type.
  },
  {
    id: "text",
    name: "Text",
    description: "A text field.",
    color: "#333333",
    icon: { name: "string", type: "material" },
    renderer: sdtTextRenderer,
    defaultSDT: { type: "text" },
    defaultValue: "",
    schema: SDTTextSerializedSchema
  },
  {
    id: "number",
    name: "Number",
    description: "A number field.",
    color: "#333333",
    icon: { name: "number", type: "material" },
    renderer: sdtNumberRenderer,
    defaultSDT: { type: "number" },
    defaultValue: 0,
    schema: SDTNumberSerializedSchema
  },
  {
    id: "object",
    name: "Object",
    description: "An object field.",
    color: "#333333",
    icon: { name: "object", type: "material" },
    renderer: sdtObjectRenderer,
    defaultSDT: { type: "object", extensible: true, properties: {} } as any,
    defaultValue: {},
    schema: SDTObjectSerializedSchema
  },
  // {
  //   id: "rating",
  //   name: "Rating",
  //   description: "A 1 - 10 rating field.",
  //   color: "#333333",
  //   icon: { name: "star", type: "material" },
  //   renderer: sdtRatingRenderer,
  //   defaultSDT: { type: "rating" } as any
  // },
  {
    id: "boolean",
    name: "Boolean",
    description: "A boolean field.",
    color: "#333333",
    icon: { name: "boolean", type: "material" },
    renderer: sdtBooleanRenderer,
    defaultSDT: { type: "boolean" },
    defaultValue: false,
    schema: SDTBooleanSerializedSchema
  },
  {
    id: "date",
    name: "Date",
    description: "A date field.",
    color: "#333333",
    icon: { name: "date", type: "material" },
    renderer: sdtDateRenderer,
    defaultSDT: { type: "date" },
    defaultValue: new Date().toISOString(),
    schema: SDTDateSerializedSchema
  },
  {
    id: "option",
    name: "Option",
    description: "An option field.",
    color: "#333333",
    icon: { name: "option", type: "material" },
    renderer: sdtOptionRenderer,
    defaultSDT: { type: "option", options: [] } as any,
    defaultValue: undefined,
    schema: SDTOptionSerializedSchema
  },
  {
    id: "array",
    name: "Array",
    description: "An array field.",
    color: "#333333",
    icon: { name: "array", type: "material" },
    renderer: sdtArrayRenderer,
    defaultSDT: { type: "array", itemType: { type: "keyword" } } as any,
    defaultValue: [],
    schema: SDTArraySerializedSchema
  },
  {
    id: "sdt",
    name: "SDT",
    description: "An SDT field.",
    color: "#333333",
    icon: { name: "form", type: "material" },
    renderer: sdtSDTRenderer,
    defaultSDT: { type: "sdt" },
    defaultValue: { type: "keyword" },
    schema: SDTTypeSerializedSchema  //  NOTE:  A schema is a TEMPLATE used to validate an encoding.  In this case, we have a TEMPLATE which is used to validate a TEMPLATE used to validate each type.  This is useful if we want to let the user create their own type template!
  },
  {
    id: "any",
    name: "Any",
    description: "A field that can take any value.",
    color: "#333333",
    icon: { name: "any", type: "material" },
    renderer: sdtAnyRenderer,
    defaultSDT: { type: "any" },
    defaultValue: undefined,
    schema: SDTAnySerializedSchema
  },
  {
    id: "binary",
    name: "Binary",
    description: "A binary field.",
    color: "#333333",
    icon: { name: "binary", type: "material" },
    renderer: sdtBinaryRenderer,
    defaultSDT: { type: "binary" },
    defaultValue: undefined,
    schema: SDTBinarySerializedSchema
  },

  //
  //  Habor Types
  //  TODO:  Register from their respective Plugins
  //

  {
    id: "statement",
    name: "Statement",
    description: "A statement field.",
    color: "#333333",
    icon: { name: "statement", type: "material" },
    renderer: sdtTextRenderer,
    defaultSDT: { type: "text" }
  },
  {
    id: "noun",
    name: "Noun",
    description: "A noun field.",
    color: "#333333",
    icon: { name: "noun", type: "material" },
    renderer: sdtNounRenderer,
    defaultSDT: { type: "noun" }
  },
  {
    id: "relationship",
    name: "Relationship",
    description: "A relationship field.",
    color: "#333333",
    icon: { name: "link", type: "material" },
    renderer: sdtRelationshipRenderer,
    defaultSDT: { type: "relationship", nounIds: [] } as any,
    schema: HSDTRelationshipSerializedSchema
  },
  {
    id: "color",
    name: "Color",
    description: "A color field.",
    color: "#333333",
    icon: { name: "color", type: "material" },
    renderer: sdtColorRenderer,
    defaultSDT: { type: "color" }
  },
  {
    id: "hex-color",
    name: "Hex Color",
    description: "Hex Color Field.",
    color: "#333333",
    icon: { name: "color", type: "material" },
    renderer: sdtHexColorRenderer,
    defaultSDT: { type: "hex-color" }
  },
  {
    id: "icon",
    name: "Icon",
    description: "An Icon Field.",
    color: "#333333",
    icon: { name: "image", type: "material" },
    renderer: sdtIconRenderer,
    defaultSDT: { type: "icon" }
  },
  // {
  //   id: "derived",
  //   name: "Derived",
  //   description: "A derived field.",
  //   color: "#333333",
  //   icon: { name: "link", type: "material" },
  //   renderer: sdtDerivedRenderer,
  //   defaultSDT: { type: "derived", path: [] } as any,
  //   schema: HSDTDerivedSchema
  // },
  {
    id: "custom-type",
    name: "Custom Type",
    description: "A custom type field.",
    color: "#333333",
    icon: { name: "custom", type: "material" },
    renderer: sdtCustomTypeRenderer,
    defaultSDT: { type: "custom-type" },

  },


];

export const getDavelType = (type?: string) => {
  return davelTypeRegister.find(davelType => davelType.id === type);
}

export const getRenderer = (type: string) => {
  return davelTypeRegister.find(davelType => davelType.id === type)?.renderer;
}

export const getSchema = (type: string) => {
  return davelTypeRegister.find(davelType => davelType.id === type)?.schema;
}

// export const getSchema = (type: string) => {
//   return davelTypeRegister.find(davelType => davelType.id === type)?.renderer;
// }

// export const getTypeToSchemaMap = () => {
//   const typeToSchemaMap = {};
//   davelTypeRegister.forEach(davelType => typeToSchemaMap[davelType.id] = davelType.renderer);
//   return typeToSchemaMap;
// }

export interface MagicComponentButtonProps {
  iconType: IconType;
  iconName: string;
  onPress: () => void;
}
export const MagicComponentButton = (props: MagicComponentButtonProps) => {

  //  Unpack
  const { iconType, iconName, onPress } = props;

  return (
    <View>
      <TouchableOpacity style={{ height: 34, width: 34, borderRadius: 17, backgroundColor: '#EEEEEE', alignItems: 'center', justifyContent: 'center' }}>
        <Icon type={iconType} name={iconName} size={17} color={'#AAAAAA'} />
      </TouchableOpacity>
    </View>
  );
}

export interface MagicComponentProps {
  component: HaborComponent;
  children: any;
}
export interface MagicComponentState { }

/**
 * Every "MagicComponent" is configurable!  We can ALSO configure WHAT can be configure for the component.
 * Every "Magic Component" has support for a Selector, Settings, and Forking.  FOR NOW, these services are hard-coded, but we COULD support additional Plugable services or service providers.
 */
export class MagicComponent extends React.Component<MagicComponentProps, MagicComponentState> {

  public render = () => {
    const { children } = this.props;
    return (
      <View style={{ borderRadius: 15 }}>
        <View style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: 10 }}>
          <MagicComponentButton iconName="sync" iconType="material" onPress={() => null} />
          <View style={{ width: 5 }} />
          <MagicComponentButton iconName="settings" iconType="material" onPress={() => null} />
          <View style={{ width: 5 }} />
          <MagicComponentButton iconName="edit" iconType="material" onPress={() => null} />
        </View>
        <View style={{ padding: medSpacer }}>
          {children}
        </View>
      </View>
    );
  };
}

//  FOR NOW, it's all done based on Type Name.  We can register MULTIPLE UIs fot the same type, like "Number".  However... we MIGHT want to condition he set of UIs on the Type Options?  Like ONLY show integer selects for integer types?  Vs... Natural Numbers?  MAYBE the UI can instead support the options of the type??  Yes, I like that.  This may include the specifici instantiation of a Schema Custom type or inherited noun?




// export const renderSDT = async (params: SDTRendererParams) => {

//   const { sdt, name, formId, topLevel, metadata, value, autoSubmit } = params;

//   //  Validate SDT
//   const dt = deserializeSDT(sdt);
//   await dt;


//   //  Render
//   return <SDTComponent {...{ sdt, name, topLevel, formId, metadata, value, autoSubmit }} key={ name } />
// };

//
//  Transformers
//

export type SDTTransformer = (value: any, sdt: any, name: string) => any;

const nullTransformer = (flatValues: any, sdt: SDT, name: string) => undefined;
const jsonTransformer = (flatValues: any, sdt: SDT, name: string) => JSON.parse(flatValues[name]);

export const registerTypeTransformer = (type: string, transformer: SDTTransformer) => {
  typeToTransformer[type] = transformer;
}

export const typeToTransformer: { [type: string]: SDTTransformer } = {

  //  Built In
  keyword: keywordTransformer,
  text: nullTransformer,
  number: nullTransformer,
  object: sdtObjectTransformer,
  boolean: nullTransformer,
  date: nullTransformer,
  option: nullTransformer,
  array: nullTransformer,
  sdt: sdtTransformer,
  any: nullTransformer,

  //  Habor Custom
  //  TODO:  Register with a function as done in Habor / Davel.
  statement: nullTransformer,
  noun: nullTransformer,
  dependency: nullTransformer,
  relationship: nullTransformer
};

export const transformSDT = (flatValues: any, sdt: SDT, name: string) => {
  //  TODO:  Validate the SDT Value!  Maybe do this in a Field validator?

  //  Get the Transformer
  const transformer = typeToTransformer[sdt.type];
  if (!transformer) { throw new Error(`No transformer registered for the specified type (${sdt.type}).`); }

  //  Transform
  try {
    transformer(flatValues, sdt, name);
    // console.log('success');
  } catch (err) {
    console.warn(err);
    throw err;
  }
};

//  TODO:  Create a Davel 'view' to display date with a given schema.
// const createDavelView = (schema: SDT, data: any) => {}

//
//  ViewRenderers
//

//  TODO:  Consider Icons and custom view renderers??  We can KIND of already do this with the Embedded thing.

const sdtKeywordViewRenderer = async ({ value }: SDTViewRendererParams) => {
  return (
    //  TODO:  Move fontFamily italic to component prop logic.
    <TextParagraph style={value ? {} : { fontFamily: 'Poppins-MediumItalic' }}>{value ? value : 'undefined'}</TextParagraph>
  );
};

const sdtTextViewRenderer = async ({ value }: SDTViewRendererParams) => {
  return (
    //  TODO:  Move fontFamily italic to component prop logic.
    <TextParagraph style={value ? {} : { fontFamily: 'Poppins-MediumItalic' }}>{value ? value : 'undefined'}</TextParagraph>
  );
};

const basicSDTViewRenderer = async ({ value }: SDTViewRendererParams) => {
  return (
    //  TODO:  Move fontFamily italic to component prop logic.
    <TextParagraph style={value ? {} : { fontFamily: 'Poppins-MediumItalic' }}>{value ? JSON.stringify(value) : 'undefined'}</TextParagraph>
  );
};

//  TODO:  Move to the 'any' folder.
const anySDTViewRenderer = async ({ value }: SDTViewRendererParams) => {
  return (
    //  TODO:  Move fontFamily italic to component prop logic.
    <TextParagraph style={value ? {} : { fontFamily: 'Poppins-MediumItalic' }}>{value ? JSON.stringify(value) : 'undefined'}</TextParagraph>
  );
};

const typeToViewRenderer: { [type: string]: SDTViewRenderer } = {
  keyword: sdtKeywordViewRenderer,
  text: sdtTextViewRenderer,
  number: basicSDTViewRenderer,
  object: sdtObjectViewRenderer,
  boolean: sdtBooleanViewRenderer,
  date: basicSDTViewRenderer,
  option: basicSDTViewRenderer,
  array: sdtArrayViewRenderer,
  sdt: basicSDTViewRenderer,
  any: anySDTViewRenderer,

  noun: basicSDTViewRenderer,
  dependency: basicSDTViewRenderer,
  relationship: sdtRelationshipViewRenderer,
  "custom-type": sdtCustomTypeViewer
};

export const renderObjectView = async (sdtObject: SDTObject, value: any, name: string, metadata: any) => {
  const res = await renderView({ sdt: sdtObject, value, name, metadata, topLevel: true });
  return res;
};

/**
 * Component to render a Davel Field
 */
export interface FieldViewProps {
  sdt: SDT;
  value: any;
  name: string;
  metadata: any;
  topLevel?: boolean;
}
interface FieldViewState {
  view: any;
}
export class FieldView extends React.Component<FieldViewProps, FieldViewState> {
  constructor(props: FieldViewProps) {
    super(props);
    this.state = {
      view: undefined
    }
  }
  public componentDidMount = async () => {

    //  Unpack
    const { sdt, value, name, metadata, topLevel } = this.props;

    //  Render the View
    const view = await renderView({ sdt, value, name, metadata, topLevel });

    this.setState({ view });
  }

  public render = () => {

    //  Unpack
    const { view } = this.state;

    //  Guard Undefined
    //  TODO:  Make a nicer loading indicator.
    if (view == undefined) { return <Text>Loading...</Text> }

    //  Return the Element
    return view;
  }
}

export const renderView = async ({ sdt, value, name, metadata, topLevel = false }: { sdt: SDT, value: any, name: string, metadata?: any, topLevel?: boolean }) => {

  //  Validate SDT
  //  TODO:  Consider whether or not to validate here.
  // const dt = await deserializeSDT(sdt);

  //  Validate the value
  //  TODO:  Will need to pass metadata or fix this pattern to validate state dependent pieces.
  //  TODO:  Consider whether or not to validate here.
  // await dt.validate(value);

  //  Get the Renderer
  const renderer = typeToViewRenderer[sdt.type];
  if (!renderer) { throw new Error(`No view renderer registered for the specified type (${sdt.type}).`); }

  //  Render
  const params: SDTViewRendererParams = { sdt, value, name, topLevel, metadata };
  const view = renderer(params);
  return view;
};
