
import * as React from 'react';
import { Text } from 'react-native-paper';
import { PropertyPlugin } from '../../hessia2/property-plugin';
import { Entity2Plugin } from '../../hessia2/entity-plugin';
import { Hessia2Plugin, System } from '../../hessia2/hessia2-plugin';
import { PatternPlugin } from '../../hessia2/pattern-system';
import { HabitPlugin } from './habits.extension';
import { HierarchyPlugin } from './hierarchy.extension';
import { TagSetPlugin } from './tag.template';

//  CONSIDER:  We SHOULD be able to build ANY predicate and do this on the fly!

//  Build the Category Node
//  Build Categories as "Instance Of" Category
//  Category HABIT Filter!!!

//  TODO:  General PREDICATE Browser for an ENTITY!
//  TODO:  CATEGORY Browswer for COMPLEX Shapes!
//  TODO:  Category ENTITY Filter
//  TODO:  SHOULD be POLYMORPHIC.  Build a NEW sub-type of "Child Of".  THIS way this relationship can be used in those places.  Polymorphic.

// THOUGHT:   Writing to differentiate is like adding new neurons and updating the weights.. it's like you're reprogramming by changing them?

//  CONSIDER:  I'd VERY much like to be able to COLOR the Category and add other info... but I DON'T know how to do that in a GENERIC way... the idea is, sometimes I want a particular format, and we're PROJECTING from what we DO know!

// export const CategoryPluginContext = React.createContext<CategoryPlugin | undefined>(undefined);

export class Category2Plugin extends TagSetPlugin {

  public PluginContext = React.createContext<Category2Plugin | undefined>(undefined);

  public static details = {
    ...TagSetPlugin.details,
    name: 'Category',
    description: 'Introduces Categories',
    id: "category"
  }

  public tagSetId = "category-type";

  public system: System = {
    name: "Category",
    description: "Adds Categories",
    id: "category-system",
    icon: { name: "albums-outline", type: "ionicon" },
    emoji: "",
    color: "#eeeeee",
    primaryColor: "#aaaaaa",
    component: () => <Text>Category System</Text>,
    backgroundColor: "#555555"
  }
}