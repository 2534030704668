import { CorePluginClass, Program } from 'halia';
import { PropertyPlugin } from './property-plugin';
import { SystemMixin } from './system';
import { PatternPlugin } from './pattern-system';
import { OwnershipMixin } from './owner-mixin';
import { PrimitiveMixin } from './primitive-mixin';

export interface ICorePlugin {
  mixinInstallers: any[];
  graph: PropertyPlugin;
  type: PatternPlugin;
}

//  NOTE:  Got some advice from ChatGPT to build a system that allowed me to compose classes.  I had this idea a long time ago, but here it is (using mixins as recommended by GPT) 

/**
 * Manages the Mixins:  Holds their installers for installation.
 */
export function MixinManager<T extends new (...args: any[]) => CorePluginClass>(Base: T) {
  return class extends Base {
    public mixinInstallers: Function[] = [];
    constructor(...args: any[]) {
      super(...args);
      console.log("Initializing Core Plugin Mixin");
      this.mixinIpnstallers = [];
    }

    protected installMixins = async () => {
      for (const installer of this.mixinInstallers) {
        await installer();
      }
    }
  };
}

export class CorePlugin extends PrimitiveMixin(OwnershipMixin(SystemMixin(MixinManager(CorePluginClass)))) {

  constructor() {
    super();
  }

  public static details = {
    name: 'Core Plugin',
    description: 'Provides initial functionality',
    dependencies: [PropertyPlugin.details.id, PatternPlugin.details.id],
    id: 'core'
  }

  public graph!: PropertyPlugin;
  public type!: PatternPlugin;


  public install = async (program: Program, { graph2, type2 }: { graph2: PropertyPlugin, type2: PatternPlugin }) => {

    this.graph = graph2;
    this.type = type2;

    await this.installMixins();

    return this;
  }
}
