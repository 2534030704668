import { CorePluginClass } from "halia";
import { ICorePlugin } from "./core-plugin";
import { PatternPlugin } from "./pattern-system";
import { PropertyPlugin } from "./property-plugin";

const SYSTEM_NODETYPE_NODE_ID = "system_NodeType";
const SYSTEM_NODETYPE_TYPEDEF_EDGE_ID = "system_NodeType_TypeDef";

const CORESYSTEM_SYSTEM_INSTANCE_NODE_ID = "coreSystem_SystemInstance";
const CORESYSTEM_SYSTEM_INSTANCE_TYPEDEF_EDGE_ID = "coreSystem_SystemInstanceDef";

export function SystemMixin<T extends new (...args: any[]) => CorePluginClass>(Base: T) {

  return class SystemPlugin extends Base implements ICorePlugin {

    public mixinInstallers!: any[];
    public graph!: PropertyPlugin;
    public type!: PatternPlugin;

    constructor(...args: any[]) {
      super(...args);
      this.mixinInstallers.push(this.installSystem)
    }

    public registerSystem = async (srcId, id?: string) => {
      await this.type.registerInstance(srcId, SYSTEM_NODETYPE_NODE_ID, id);
    }

    protected installSystem = async () => {

      //  System Type
      await this.type.createTypeIfNeeded({ id: SYSTEM_NODETYPE_NODE_ID, entityType: "node", name: "System", description: "System Type", emoji: "📚" });
      const systemTypeEdge = await this.graph.getEdgeById(SYSTEM_NODETYPE_TYPEDEF_EDGE_ID);
      if (!systemTypeEdge) {
        await this.type.registerType(SYSTEM_NODETYPE_NODE_ID, SYSTEM_NODETYPE_TYPEDEF_EDGE_ID)
      }

      //  Register Core Systems
      const coreSystemNode = await this.graph.getNodeById(CORESYSTEM_SYSTEM_INSTANCE_NODE_ID);
      if (!coreSystemNode) {
        await this.graph.createNode({ id: CORESYSTEM_SYSTEM_INSTANCE_NODE_ID, name: "Core System", description: "The Core Hessia System", entityType: "node", emoji: "🔵" });
        await this.registerSystem(CORESYSTEM_SYSTEM_INSTANCE_NODE_ID, CORESYSTEM_SYSTEM_INSTANCE_TYPEDEF_EDGE_ID);
      }

    }
  }
}