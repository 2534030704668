import { createDrawerNavigator, DrawerContentComponentProps } from '@react-navigation/drawer';
import { CommonActions, NavigationContainer, useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Amplify, { Auth } from 'aws-amplify';
import { CorePluginClass } from "halia";
import { HaliaComponentPlugin, HOCRegister, registerHOC, registerHOCRegister, removeHOC } from 'halia-native';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Clipboard, FlatList, Image, PanResponder, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, ViewProps } from "react-native";
import { Icon } from "react-native-elements";
import { MD3LightTheme as DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { View } from 'react-native-web';
import { AuthContext, AuthProvider } from "../../packages/kelp-bar/auth";
import { Picker } from '../../packages/kelp-bar/picker';
import { useSizes } from "../../packages/kelp-bar/sizes-helper";
import { primaryFontFamily, primaryFontFamilyHeavy } from '../../packages/kelp-bar/styles';
import { SystemHeader } from '../../packages/kelp-bar/system-header';
import { NamedObject, Widget, WidgetWrapper } from '../../packages/widgets';
import { GalleryButton } from "../gallery/components/common";
import { GroupCard } from '../gallery/components/group-card';
import { awsConfig, Header } from '../gallery/constants';
import { useAppType } from "../gallery/sizes-helper";
import { PluginStore } from "../halia-studio/halia-studio-plugin/plugin-list";
import { Authenticate } from "./authenticate";
import { getConfigFromStorage, getCredentials, HessiaConfig, setConfigInStorage } from './config.service';
import { EntityContext } from "./entity-plugin";
Amplify.configure(awsConfig);
const uuid = require('uuid/v4');

//  CONSIDER:  Should this be INJECTED by the "Entity" Plugin??
//  CONSIDER:  Could / should we use a NounService instead of doing this manually??

/**
 * NOTE:  Generated by GPT!
 * TODO:  Make a library of AWS / Amplify tools.
 * @returns 
 */

export interface Profile {
  username: string;
  email?: string;
  sub?: string;
  identityId: string;
}
const useProfile = () => {
  const [userInfo, setUserInfo] = useState<Profile | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  // Fetch the user info when the component mounts
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const credentials = await Auth.currentCredentials();
        const identityIdFromIdentityPool = credentials.identityId;
        const { username, attributes } = user;
        // Set user info
        setUserInfo({ username, identityId: identityIdFromIdentityPool, ...attributes });
      } catch (error) {
        console.error('Error fetching user info:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  return userInfo;
};


const Settings = () => {

  const { hessia2Plugin } = React.useContext(Hessia2Context);
  const [storedConfig, setStoredConfig] = React.useState<HessiaConfig | undefined>(undefined);
  const [_config, _setConfig] = React.useState<HessiaConfig | undefined>(undefined);
  const { syncState } = React.useContext(EntityContext);
  const profile = useProfile();
  const previousConfigRef = React.useRef<HessiaConfig | undefined>(undefined);

  const updateConfig = async () => {
    if (!_config) { return; }
    if (_config?.storage === 'cloud') {
      _setConfig({ ..._config, s3Config: undefined });
      previousConfigRef.current = _config;
    }
  }

  const loadConfig = async () => {
    const _storedConfig = await getConfigFromStorage();

    //  Cloud - If we're loading from the cloud, update the credentials.
    if (_storedConfig?.storage === 'cloud') {
      const credentials = await getCredentials();
    }
    _setConfig(_storedConfig);
    setStoredConfig(_storedConfig);
    previousConfigRef.current = _storedConfig;
  }

  React.useEffect(() => {
    loadConfig();
  }, []);

  React.useEffect(() => {
    if (JSON.stringify(previousConfigRef.current) !== JSON.stringify(_config)) {
      updateConfig();
    }
  }, [_config]);

  if (!_config) {
    return <Text>Loading Config</Text>
  }

  const saveSettings = async () => {
    await setConfigInStorage(_config);
    setStoredConfig(_config);
  };

  // const awsEnabled = _config?.storage == 'aws';
  const dirty = (JSON.stringify(_config) != JSON.stringify(storedConfig));

  // const toggleAws = () => {
  //   _setConfig({ ..._config, storage: _config.storage === 'aws' ? 'local' : 'aws' });
  // }

  const updateS3Config = (field: string, value: string) => {
    const s3Config = _config.s3Config || { bucket: "", bucketKey: "", region: "", credentials: {} };
    _setConfig({ ..._config, s3Config: { ...s3Config, [field]: value } });
  }

  /**
   * TODO:  Display PROFILE Picture.
   * TODO:  Display USERNAME
   * TODO:  Update EMAIL
   */
  const { logout } = React.useContext(AuthContext);

  const pick = (value: "custom" | "local" | "cloud") => {
    _setConfig({ ..._config, storage: value });
  }

  return (
    <View style={{ backgroundColor: 'white', flex: 1 }}>

      <SystemHeader breadcrumbs={false} system={{ name: "Account", icon: { type: "feather", name: "user" } }} />

      {/* We want to show the user... there are three possiblities?  We are LOGGED OUT, or we're LOGGED IN.  Then, it's up to us what we do...  */}


      {
        profile && profile.email && (
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Text style={{ fontFamily: primaryFontFamily }}>Logged in as: </Text>
            <View style={{ marginVertical: 10, width: 'auto', backgroundColor: '#d4e9ff', borderRadius: 15, height: 30, paddingHorizontal: 15, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ fontFamily: primaryFontFamily, color: '#5cacff', marginLeft: 5 }}>{profile.email}</Text>
            </View>
          </View>

        )
      }

      {
        profile && profile.username && (
          <TouchableOpacity onPress={() => { Clipboard.setString(profile.username); alert("Saved!"); }} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Text style={{ fontFamily: primaryFontFamily }}>Logged in as: </Text>
            <View style={{ marginVertical: 10, width: 'auto', backgroundColor: '#d4e9ff', borderRadius: 15, height: 30, paddingHorizontal: 15, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ fontFamily: primaryFontFamily, color: '#5cacff', marginLeft: 5 }}>{profile.username}</Text>
            </View>
          </TouchableOpacity>

        )
      }

      {
        profile && profile.sub && (
          <TouchableOpacity onPress={() => { Clipboard.setString(profile?.sub || ""); alert("Saved!"); }} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Text style={{ fontFamily: primaryFontFamily }}>Logged in as: </Text>
            <View style={{ marginVertical: 10, width: 'auto', backgroundColor: '#d4e9ff', borderRadius: 15, height: 30, paddingHorizontal: 15, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ fontFamily: primaryFontFamily, color: '#5cacff', marginLeft: 5 }}>{profile.sub}</Text>
            </View>
          </TouchableOpacity>

        )
      }

      <Header style={{ fontFamily: primaryFontFamilyHeavy }}>Sources</Header>

      <Text style={{ fontFamily: primaryFontFamilyHeavy, marginLeft: 5 }}>Cloud</Text>
      <GroupCard>
        <Text>This is your space in the Hessia Cloud</Text>
        {profile ? <Text>Because you're logged in, you have a space!</Text> : <Text>You are not logged in to Hessia.</Text>}
      </GroupCard>

      <Text style={{ fontFamily: primaryFontFamilyHeavy, marginLeft: 5 }}>Local</Text>
      <GroupCard>
        <Text>The local space is where data is processed and is always enabled.</Text>
      </GroupCard>

      <Text style={{ fontFamily: primaryFontFamilyHeavy, marginLeft: 5 }}>Custom</Text>
      <GroupCard>
        <Text>Used to send to your OWN AWS bucket:</Text>
        <Text>Use Custom Bucket?</Text>
        {/* <Switch value={awsEnabled} onValueChange={toggleAws} /> */}
        <TextInput placeholder="Bucket" value={_config.s3Config?.bucket} onChangeText={(value) => updateS3Config("bucket", value)} />
        <TextInput placeholder="Bucket Key" value={_config.s3Config?.bucketKey} onChangeText={(value) => updateS3Config("bucketKey", value)} />
        <TextInput placeholder="Key" value={_config.s3Config?.credentials.key} onChangeText={(value) => updateS3Config("key", value)} />
        <TextInput placeholder="Region" value={_config.s3Config?.region} onChangeText={(value) => updateS3Config("region", value)} />
        <TextInput placeholder="Secret" value={_config.s3Config?.credentials.secret} onChangeText={(value) => updateS3Config("secret", value)} />

        <Picker grow={true} value={_config.storage} onChange={pick} items={[{ value: 'local', label: 'Local' }, { value: 'cloud', label: 'Cloud' }, { value: 'custom', label: 'Custom' }]} />
      </GroupCard>

      {
        dirty && <Button title="Save" onPress={saveSettings} />
      }

      <Text>Saved?</Text>
      <Text>{syncState}</Text>

      <Button title="Logout" onPress={() => { logout() }} />
    </View>
  );
}

//
//  Systems
//

export interface System {
  pinned?: boolean;  //  TODO:  This should NOT be a part of the system?
  priority?: number; //  TODO:  This should NOT be part of the system?
  id: string;
  name: string;
  description: string;
  emoji: string;
  color: string;
  component: any;
  focus?: boolean;
  icon: {
    name: string;
    type: string;
  },
  primaryColor: string;
  backgroundColor: string;

  //  CONSIDER:  We may have other info associated with it, but perhaps it's easier to make these mappings in the system itself.
  //  CONSIDER:  We may want to require entity providers and other standards.
}

const SystemsList = ({ systems }: { systems: System[] }) => {
  const navigation = useNavigation();
  return (
    <>
      <Text style={{ fontSize: 16, fontFamily: 'Poppins-SemiBold', marginVertical: 10 }}>Systems</Text>
      <FlatList data={systems} renderItem={({ item: system }) => <GalleryButton emoji={system.emoji} style={{ backgroundColor: system.color }} title={system.name} onPress={() => navigateToScreen(system.name as never, navigation)} />} />
    </>


  );
}


//
//  Interface
//

const DrawerNav = createDrawerNavigator();

function hexToRgbString(hex) {
  let r = 0, g = 0, b = 0;

  // Handling 3 digits hex color
  if (hex.length == 4) {
    r = parseInt("0x" + hex[1] + hex[1]);
    g = parseInt("0x" + hex[2] + hex[2]);
    b = parseInt("0x" + hex[3] + hex[3]);

    // Handling 6 digits hex color
  } else if (hex.length == 7) {
    r = parseInt("0x" + hex[1] + hex[2]);
    g = parseInt("0x" + hex[3] + hex[4]);
    b = parseInt("0x" + hex[5] + hex[6]);
  }

  return `${r},${g},${b}`;
}

export interface CustomSVG {
  color: string;
  size: number;
}

// export const SystemTitle = ({ name, primaryColor, icon, backgroundColor }: System2) => {
//   const navigation = useNavigation();
//   return (
//     <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', borderRadius: 5, marginBottom: 5, paddingVertical: 5, justifyContent: "flex-start", height: 50, borderColor: `#eeeeee`, borderWidth: 0 }}>
//       <SystemIcon icon={icon} backgroundColor={backgroundColor} primaryColor={primaryColor} />
//       <Text style={{ fontFamily: 'Inter-SemiBold', fontSize: 16, color: '#555555', marginLeft: 10 }}>{capitalizeAllWords(name)}</Text>
//     </View>
//   );
// }

//  REFERENCE:  GPT-4
const ResizableColumn = (props: ViewProps = { style: {} }) => {
  const [width, setWidth] = React.useState((props.style as any)?.width); // Initial width
  const [isHovered, setIsHovered] = React.useState(false); // Is mouse over the handle?

  const panResponder = React.useRef(PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onPanResponderMove: (evt, gestureState) => {
      setWidth(gestureState.moveX);
    },
    onPanResponderEnd: () => {

    }
  })).current;

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <View style={[styles.column, props.style, { width }]}>
      <View style={{ flex: 1 }}>
        {props.children}
      </View>
      <View
        style={[styles.handle, { width: isHovered ? 5 : 1 }]}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...panResponder.panHandlers}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  column: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  handle: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 1,
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: '#eeeeee', // Make the handle slightly visible
  },
});

export default ResizableColumn;


// const LeftMenu = ({ systems, selectedSystem }: { systems: System2[], selectedSystem }) => {


//   const navigation = useNavigation();

//   return (
//     <ResizableColumn style={{ backgroundColor: 'white', width: 200, borderRightWidth: 1, borderRightColor: '#eeeeee' }}>

//       {/* Systems Widget */}
//       <View style={{ flex: 1, padding: 20 }}>

//         <Text style={{ fontFamily: 'Inter-Bold', fontSize: 11, color: '#888888', marginBottom: 16, letterSpacing: 2 }}>SYSTEMS</Text>

//         {/* rgba(${ hexToRgbString(system.primaryColor) },0.2) */}
//         {
//           systems.map(system => (

//             <SystemTitle {...system} />

//           ))
//         }
//       </View>
//       <View style={{ padding: 20, borderTopWidth: 1, borderTopColor: '#eeeeee' }}>
//         <Text style={{ fontFamily: 'Inter-SemiBold', fontSize: 12, color: '#333333', marginBottom: 16 }}>Settings</Text>
//         <SystemTitle name="Plugins" primaryColor='#19abff' backgroundColor='#def0ff' icon={{ name: 'plug', type: 'font-awesome' }} onPress={() => navigation.navigate("Extensions" as never)} />
//         <SystemTitle name="Clear" primaryColor='#19abff' backgroundColor='#def0ff' icon={{ name: 'close', type: 'font-awesome' }} onPress={async () => { alert("Attempting Clear");  await AsyncStorage.clear(); alert("Cleared Async"); }} />
//       </View>

//     </ResizableColumn>
//   );


// }


const navigateToScreen = (screenName, navigation) => {
  navigation.dispatch(
    CommonActions.reset({
      index: 0,
      routes: [{ name: screenName }],
    }),
  );
};


export interface HessiaDrawerContentFactoryOptions {
  logoUrl: string;
}


const HessiaLeftPanel = () => {

  const { systems } = React.useContext(Hessia2Context);
  const { setSelectedView, setShowPanel } = React.useContext(NavContext);

  //  TODO:  We SHOULD be pulling the contents of this view dynamically.  FOR NOW, we'll keep it hard-coded.. but I REALLY like the idea of being able to mix / match views any way we like then reset to default if we need to.  But, I ALSO like the idea of keeping these stable and letting custom views handle the customization.

  const SystemSelector = () => {

    const [collapsed, setCollapsed] = React.useState(false);
    const toggle = () => {
      setCollapsed(!collapsed);
    }

    return (
      <TouchableOpacity onPress={toggle} style={{ width: '100%', borderRadius: 10, borderWidth: collapsed ? 1 : 0, borderColor: '#eeeeee' }}>
        <View style={{ height: 40, paddingHorizontal: 15, alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row' }}>
          <Icon style={{ marginRight: 20 }} name="cpu" type="feather" color="#777" size={18} />
          <Text style={{ fontFamily: primaryFontFamilyHeavy, fontSize: 16, color: "#555" }}>Systems</Text>
          <View style={{ flex: 1 }} />
          <View style={{ height: 22, paddingHorizontal: 7, alignItems: 'center', justifyContent: 'center', borderRadius: 5, borderWidth: 1, borderColor: '#eee' }}>
            <Text style={{ fontFamily: primaryFontFamily, fontSize: 14, color: "#888" }}>{ systems.length }</Text>
          </View>
          <Icon style={{ marginLeft: 5 }} name={collapsed ? "chevron-left" : "chevron-down"} type="feather" color="#ddd" size={18} />
        </View>

        {
          !collapsed && (
            systems.map(system => (
              <View style={{ height: 25, flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ marginLeft: 52, fontFamily: primaryFontFamily, color: "#aaaaaa" }} onPress={() => { setSelectedView({ name: system.name }); }}>{system.name}</Text>
              </View>
            ))
          )
        }

      </TouchableOpacity>
    )
  };

  const SystemSelectorWidget: Widget = {
    name: "System Selector",
    pluginId: Hessia2Plugin.details.id,
    component: SystemSelector
  }
  //  TODO:  Rewrite to use children as widget.

  return (
    <View style={{ flex: 1, backgroundColor: 'white', padding: 15 }}>
      <WidgetWrapper editMode={false} widget={SystemSelectorWidget} />
    </View>
  );
}

const HessiaDrawerContent = (props: DrawerContentComponentProps) => {

  const { isDesktop } = useSizes();
  const { systems } = React.useContext(Hessia2Context);

  if (!systems.length) { return <Text>Loading</Text> }

  const filteredRoutes = ["Extensions"];

  const allowedRoutes = props.state.routes.filter(
    route => !filteredRoutes.includes(route.name)
  );

  const { navigation } = props;

  const HessiaMenuButton = ({ name, focused, icon, onPress, onLongPress }: { name: string, focused: boolean, icon: { name: string, type: string }, onPress?: () => void, onLongPress?: () => void }) => {
    return (
      <TouchableOpacity onLongPress={() => onLongPress ? onLongPress() : null} style={{ marginHorizontal: 0, marginBottom: 0, height: 54, paddingHorizontal: 15 }} onPress={() => onPress ? onPress() : navigation.navigate(name)}>
        <View style={{ backgroundColor: focused ? '#fff9fb' : 'transparent', borderRadius: focused ? 22 : 0, height: 44, width: '100%', paddingHorizontal: 15, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', borderColor: 'transparent', borderWidth: focused ? 2 : 0 }}>
          <Icon size={22} type={icon.type} name={icon.name} color={focused ? "#f63d75" : "#666666"} />
          <Text style={{ fontFamily: 'Outfit-SemiBold', color: focused ? "#f63d75" : "#666666", fontSize: 16, marginLeft: 20 }}>{name}</Text>
        </View>
      </TouchableOpacity>
    );
  }

  // const exploreFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Explore");
  // const createFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Create");
  const extensionsFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Extensions");
  const integrationsFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Integrations");
  const settingsFocused = props.state.index == props.state.routes.findIndex(route => route.name == "Settings");
  console.log(JSON.stringify(systems));

  const PinnedRoutes = () => {

    const hasPinned = systems.find(system => system.pinned === true);

    if (!hasPinned) { return null; }

    const getSelectedSystem = () => {
      for (let i = 0; i < allowedRoutes.length; i++) {
        const focused = i === props.state.index;
        const systemName = allowedRoutes[i].name;
        const system = systems.find(system => system.name === systemName);
        if (focused) { return system; }
      }
    }

    const selectedSystem = getSelectedSystem();

    return (
      <>
        {
          allowedRoutes.filter(({ name }, index) => {
            const system = systems.find(system => system.name === name);
            if (!system || !system.pinned) { return undefined; }
            return system;
          })
            .sort((a, b) => {
              const aSystem = systems.find(system => system.name === a.name);
              const bSystem = systems.find(system => system.name === b.name);
              return (aSystem?.priority || 0) - (bSystem?.priority || 0);
            })
            .map((route, index) => {
              const system = systems.find(system => system.name === route.name);
              if (!system) { return null; }
              const selected = selectedSystem?.id === system?.id;
              return <HessiaMenuButton icon={system.icon} focused={selected} name={system.name} onLongPress={() => alert(JSON.stringify(system))} />
            })
        }

        {/* Divider */}
        <View style={{ marginVertical: 20, height: 1.5, width: 160, backgroundColor: "#eeeeee", borderRadius: 1, marginHorizontal: 'auto' }} />
      </>
    );
  }



  return (
    <>

      {/* Header  */}
      {/* NOTE:  Hiding header for more real estate */}

      <ScrollView style={{ flex: 1, paddingVertical: 5 }} {...props}>

        {/* Pinned Systems */}
        {/* TODO:  The interface here should NOT be systems!  We're installing a BUTTON that's it! */}
        {/* <HessiaMenuButton icon={{ type: "octicon", name: "telescope" }} focused={exploreFocused} name="Search" onPress={() => navigation.navigate("Explore")} />
        <HessiaMenuButton icon={{ type: "feather", name: "loader" }} focused={createFocused} name="Create" onPress={() => navigation.navigate("Create")} /> */}
        <PinnedRoutes />

        {/* Normal Systems */}
        {/* TODO:  This should be a Widget Area */}
        {allowedRoutes.map(({ name }, index) => {
          const focused = index === props.state.index;
          const system = systems.find(system => system.name === name);
          if (!system || system.pinned) { return <></> }
          return <HessiaMenuButton icon={system.icon} focused={focused} name={name} onLongPress={() => alert(JSON.stringify(system))} />
        })}
      </ScrollView>

      {/* Divider */}
      <View style={{ marginVertical: 20, height: 1.5, width: 160, backgroundColor: "#eeeeee", borderRadius: 1, marginHorizontal: 'auto' }} />

      {/* Extensions Button */}
      {/* <HessiaMenuButton icon={{ type: "feather", name: "zap" }} focused={extensionsFocused} name="Extensions" onPress={() => navigation.navigate("Extensions")} />

      <HessiaMenuButton icon={{ type: "feather", name: "link" }} focused={integrationsFocused} name="Integrations" onPress={() => navigation.navigate("Integrations")} />
      <HessiaMenuButton icon={{ type: "feather", name: "user" }} focused={settingsFocused} name="Account" onPress={() => navigation.navigate("Settings")} /> */}

      {/* Bottom Space */}
      {/* <View style={{ height: 65, width: '100%', alignItems: 'center', backgroundColor: 'white', flexDirection: 'row' }}>

        <View style={{ flex: 1 }} />
        <Icon style={{ flex: 1 }} type="feather" name="compass" size={20} />
        <View style={{ flex: 1 }} />
        <Icon style={{ flex: 1 }} type="feather" name="zap" size={20} />
        <View style={{ flex: 1 }} />
        <Icon style={{ flex: 1 }} type="feather" name="user" size={20} />
        <View style={{ flex: 1 }} />
      </View> */}
    </>

  );
}

const sideBarColor = 'white';

//  CONSIDER:  We often hav ethe SAME thing but in different settings.. in this case, we want to inject a Menu, but whether top or bottom is determined by screen size... which dynamically changes the code path.  CAN have one component adjust OR can have two components... in a sense, it's the SAME thing... IF we can map each piece of the component to the other, then it's a false dichotomy.

export const HessiaMenuBottom = () => {

  const { setSelectedView, showPanel, setShowPanel } = React.useContext(NavContext);

  return (
    <View style={{ height: 60, width: '100%', backgroundColor: 'white', flexDirection: 'row', borderBottomColor: '#eeeeee', paddingHorizontal: 30, borderTopColor: '#eeeeee', borderTopWidth: 1, alignItems: 'center' }}>

      <Icon onPress={() => setSelectedView({ name: "settings" })} style={{ flex: 1 }} type="feather" name="user" size={26} color="#555555" />
      <View style={{ flex: 1 }} />

      <Icon onPress={() => setSelectedView({ name: "extensions" })} style={{ flex: 1 }} type="feather" name="zap" size={26} color="#555555" />
      <View style={{ flex: 1 }} />

      <Icon onPress={() => setSelectedView({ name: "create" })} style={{ flex: 1 }} type="feather" name="plus" size={30} color="#555555" />
      <View style={{ flex: 1 }} />

      <Icon onPress={() => setSelectedView({ name: "search" })} style={{ flex: 1 }} type="feather" name="search" size={26} color="#555555" />
      <View style={{ flex: 1 }} />

      <Icon onPress={() => setShowPanel(!showPanel)} style={{ flex: 1 }} type="feather" name="menu" size={26} color="#555555" />




    </View>
  );
}

export const HessiaMenuTop = () => {

  const { setSelectedView, showPanel, setShowPanel } = React.useContext(NavContext);
  const { search, setSearch } = React.useContext(SearchContext);

  return (
    <View style={{ height: 60, width: '100%', backgroundColor: 'white', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1 }}>

      {/* Logo */}
      <View style={{ height: '100%', width: 250, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 20, borderRightWidth: 0, borderRightColor: '#eeeeee', borderBottomColor: '#eeeeee', borderBottomWidth: 0 }}>
        <Image style={{ height: 35, width: 35, borderRadius: 15, marginLeft: 0 }} source={require("../../assets/stickers/Hessia Logo 6.png")} />
        <View style={{ width: 12 }} />
        <Text style={{ fontFamily: 'Outfit-SemiBold', fontWeight: "400", fontSize: 20, color: '#666666' }}>Hessia</Text>
      </View>


      <View style={{ flex: 1, backgroundColor: 'white', flexDirection: 'row', alignItems: 'center', paddingHorizontal: 30, borderTopColor: '#eeeeee', borderTopWidth: 1 }}>

        {/* Search */}
        <View style={{ maxWidth: 350, height: 40, backgroundColor: '#fdfdfd', borderRadius: 20, flex: 1, borderColor: '#eeeeee', borderWidth: 2, alignItems: 'center', flexDirection: 'row', paddingHorizontal: 15 }}>

          {/* NOTE:  Using 'outline: none' to prevent browsers from giving an outline.  This isn't a known property so I use "any" */}
          <TextInput onChangeText={text => setSearch(text)} value={search} placeholder="Search" style={[{ fontFamily: primaryFontFamily, borderWidth: 0 }, { outline: 'none' } as any]} />
        </View>

        <View style={{ flex: 1 }} />

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>

          <Icon style={{ marginHorizontal: 10 }} onPress={() => setShowPanel(!showPanel)} type="feather" name="layout" size={24} color="#555555" />
          <Icon style={{ marginHorizontal: 10 }} onPress={() => setSelectedView({ name: "extensions" })} type="feather" name="zap" size={24} color="#555555" />
          <Icon style={{ marginHorizontal: 10 }} onPress={() => setSelectedView({ name: "create" })} type="feather" name="plus-circle" size={24} color="#555555" />
          <Icon style={{ marginHorizontal: 10 }} onPress={() => setSelectedView({ name: "settings" })} type="feather" name="user" size={24} color="#555555" />

        </View>

      </View>
    </View>
  );
}

//  TODO:  Consider making this more complex for MULTIPLE windows each with different settings and track the window DEPENDENCIES as windows choose to launch child-windows.

//
//  NavContext
//

interface View extends NamedObject {
  name: string;
  component: any;
  system?: string;  //  NOTE:  EVERY thing that gets registered in Hessia comes from a system.  The trick is... it can come from multiple IF something is created by system X BECAUSE of creation of Y in system Z... so Z is "really" the root of the creation.  That requires a BIT more representation.  FOR NOW, we'll just keep it simple with a system reference.  MAY want to track which system LITERALLY called "register" hmmm... and track any callers it had to track invocation chain.
}

interface ViewSelection {
  name: string;
  params?: any;
};

export interface NavContext {
  selectedView: ViewSelection;
  setSelectedView: (view: ViewSelection) => void;
  registerView: (view: View) => void;
  views: View[];
  showPanel: boolean;
  setShowPanel: (showPanel: boolean) => void;
}

const DefaultViewSelection: ViewSelection = { name: "home", params: {} };
export const NavContext = React.createContext<NavContext>({ showPanel: false, setShowPanel: () => null, registerView: (view: View) => null, views: [], selectedView: DefaultViewSelection, setSelectedView: () => null });

const NavProvider = ({ children }) => {

  const { isDesktop } = useSizes();

  const [selectedView, _setSelectedView] = React.useState<ViewSelection>(DefaultViewSelection);
  const [showPanel, setShowPanel] = React.useState(isDesktop ? true : false);

  const setSelectedView = (view: ViewSelection) => {
    _setSelectedView(view);
    if (!isDesktop) {
      setShowPanel(false);
    }
  }
  const [views, setViews] = React.useState<View[]>([
    {
      name: "home",
      component: () => <Text>Home</Text>
    },
    {
      name: "extensions",
      component: HessiaPluginStore
    },
    {
      name: "settings",
      component: Settings
    },
    {
      name: "create",
      component: () => <Text>Create</Text>
    }
  ]);

  const registerView = (view: View) => {
    setViews((views) => {
      if (!views.find(_view => _view.name === view.name)) {
        return [...views, view];
      } else {
        return views;
      }
    });
  };

  return (
    <NavContext.Provider value={{ showPanel, setShowPanel, selectedView, setSelectedView, registerView, views }}>
      {children}
    </NavContext.Provider>
  );
}

//
//  SearchContext
//

interface SearchContext {
  search: string,
  setSearch: (search: string) => void
};

export const SearchContext = React.createContext<SearchContext>({ search: "", setSearch: () => null });

const SearchProvider = ({ children }) => {

  const [searchTerm, setSearchTerm] = React.useState("");
  const { setSelectedView } = React.useContext(NavContext);

  React.useEffect(() => {
    setSelectedView({ name: "search" });
  }, [searchTerm]);

  return (
    <SearchContext.Provider value={{ search: searchTerm, setSearch: setSearchTerm }}>
      {children}
    </SearchContext.Provider>
  );
}

const HessiaPluginStore = () => {
  return <PluginStore options={{ basePluginId: Hessia2Plugin.details.id, immediateOnly: true }} />
}

const HessiaWorkspace = () => {

  const { systems, selectedSystem, selectSystem } = React.useContext(Hessia2Context);

  const navigation = useNavigation();
  const systemRef = React.useRef(systems);
  systemRef.current = systems;

  //  Sets the Initial Route 
  //  NOTE:  ChatGPT helped with this
  //  TODO:  Make this dynamic.
  //  CONCERN:  There may be a race condition between systems changing and mounting as a screen?
  // React.useEffect(() => {
  //   for (const system of systems) {
  //     if (system.focus) {
  //       selectSystem(system);
  //       navigation.reset({
  //         index: 0,
  //         routes: [{ name: system.name as never }],
  //       });
  //       navigateToScreen(system.name as never, navigation);
  //     }
  //   }
  // }, [systems]);

  const { isDesktop } = useSizes();


  //  CONSIDER:  Register VIEWS and bind them to WINDOWS
  //  TODO:  Build a "Launch" API that lets plugins select which page will be shown.

  const { views, selectedView, showPanel, setShowPanel } = React.useContext(NavContext);

  const SelectedView = views.find(view => view.name == selectedView.name);
  const SelectedViwComponent = SelectedView?.component;

  return (

    <View style={{ flex: 1 }}>

      <View style={{ flexDirection: 'row' }}>
        {/* CONSIDER:  ALMOST feels like the component should have this knowledge... is IT going to inject itself here or elsewhere? */}
        {isDesktop && <HessiaMenuTop />}

      </View>


      {/* TODO:  Injectable Create, Search, Extensions, Explorer! (THIS is where we have widgets like Pinned, Favorites, Recents, Tags, Folders, Entities, etc... EACCH ofH of which can have more complex UI Views registered). */}

      <View style={{ flexDirection: 'row', flex: 1 }}>

        {/* Left Panel */}
        {
          showPanel && (
            <ResizableColumn style={{ zIndex: 2, position: !isDesktop ? 'absolute' : undefined, width: 250, height: '100%', borderRightWidth: 1, borderRightColor: '#eeeeee' }} >
              <HessiaLeftPanel />
            </ResizableColumn>
          )
        }

        {/* Center Content */}
        <View style={{ flex: 1 }}>
          {SelectedViwComponent && <SelectedViwComponent />}
        </View>

      </View>

      {!isDesktop && <HessiaMenuBottom />}
    </View>





  );
}
const StackNav = createStackNavigator();


const HessiaApp = () => {

  // CONSIDER:  SHOULD be able to INJECT login stuff with a pluign instead of having it here explicitly.  Enable it as a composable feature.

  const { authState } = React.useContext(AuthContext);
  console.log(JSON.stringify(authState));
  const { systems } = React.useContext(Hessia2Context);
  const { registerView } = React.useContext(NavContext);

  //  TODO:  Remember the AMAZING functional invocation / callstack I had in Habor!??  I'd REALLY like to do something like that here!!!

  React.useEffect(() => {
    //  Register System Views
    systems.forEach(system => {
      registerView({
        name: system.name,
        component: system.component
      });
    });
  }, [systems]);

  React.useEffect(() => {

    //  Register Search View
    registerView({
      name: "search",
      component: () => {
        const { search } = React.useContext(SearchContext);
        return (
          <Text>Search: {search}</Text>
        );
      }
    });
  }, []);

  if (authState.user) {
    return <HessiaWorkspace />;
  } else {
    return (
      <StackNav.Navigator screenOptions={{ headerShown: false }}>
        <StackNav.Screen name="Authenticate" component={({ navigation, route }) => <Authenticate mode={route?.params?.mode} />} />
      </StackNav.Navigator>
    );
  }

}

const HessiaProvider = ({ hessia2Plugin }: { hessia2Plugin: Hessia2Plugin }) => {

  //  TODO:  Re-Enable Navigation to Main Screen
  //  TODO:  Want to generalize this to more than just React... should be able to express systems in audio, text, etc.  THIS then is the App version I suppose.
  //  NOTE:  The settings for the app are ALWAYS stored locally.  Then, we can use the target set there to pull in the app.
  //  TODO:  Build a SYSTEM space where we can log things like app starts, events, etc!  THIS way we have a record of what we've debugged and the app journey!  

  const { registerView } = React.useContext(NavContext);

  const [systems, setSystems] = React.useState<System[]>([]);
  const systemRef = React.useRef(systems);
  systemRef.current = systems;



  const installSystem = (system: System, navigate?: boolean) => {

    //  TODO:  Register an entity for EACH system.

    setSystems(prevSystems => {
      if (!prevSystems.map(prevSystem => prevSystem.name).includes(system.name)) {
        const updatedSystems = [...prevSystems, system];
        return updatedSystems;
      }
      return prevSystems;
    });
  };

  //  CONSIDER:  Make a QUICK GPT integrator that takes a SCHEMA for an object and converts it into a component and then we can QUICKLY render a component!!!  Make a BUNCH of simple AI connected components!  STILL want to have a website for all these things mm!!!  This is part of Oranda and CAN have its own pricing model mm!

  const getSystemById = (id: string): System | undefined => {
    return systems.find(system => system.id === id);
  }
  const [selectedSystem, selectSystem] = React.useState<System | undefined>(undefined);
  const paperTheme = {
    ...DefaultTheme
  };

  //  Load Config
  React.useEffect(() => {
    getConfigFromStorage();
  }, []);

  const type = useAppType();
  return (
    <PaperProvider theme={paperTheme}>
      <AuthProvider>
        <NavProvider>
          <SearchProvider>
            <Hessia2Context.Provider value={{ selectedSystem, selectSystem, installSystem, getSystemById, systems, hessia2Plugin }}>
              <NavigationContainer independent={true}>
                <HOCRegister id="hessia2">
                  <View style={{ flexDirection: 'column', backgroundColor: 'white', flex: 1 }}>
                    {/* Top Spacing for Mobile / PWA */}
                    <View style={{ flex: 1, flexDirection: 'row', paddingTop: type != 'web' ? 54 : 0 }}>
                      <HessiaApp />
                    </View>
                  </View>
                </HOCRegister>
              </NavigationContainer>
            </Hessia2Context.Provider>
          </SearchProvider>
        </NavProvider>
      </AuthProvider>
    </PaperProvider >
  )
}

//
//  Hessia2 Plugin
//


//  TODO:  Support multiple SPACES and SETTINGS!  MAYBE this is where we select a space and configure its settings?? hmm...  Space COULD also be a system hmm..  FOR NOW, I'm just going to configure this with AWS and that's it.



export interface Hessia2Context {

  //  Systems
  installSystem: (system: System, navigate?: boolean) => void;
  getSystemById: (id: string) => System | undefined;
  systems: System[];
  selectSystem: React.Dispatch<React.SetStateAction<System | undefined>>;
  selectedSystem?: System;
  hessia2Plugin?: Hessia2Plugin;
}

export const Hessia2Context = React.createContext<Hessia2Context>({ selectSystem: (value) => null, selectedSystem: undefined, installSystem: (system, navigate) => null, getSystemById: (id: string) => undefined, systems: [] });



//  CONSIDER:  Why do we need to register this?  Can we do it dynamically based on id passed to the HOC register?  
//  TODO:  We use an HOCRegister so we can mount additional components.  HOWEVER, this breaks isolation as only one can be registered.  We will want to fix that!

/**
 * The Hessia Plugin!
 * 
 * This is the Plugin which mounts Hessia into the Halia App and makes it possible to register SYSTEMS as the FUNDAMENTAL thing.
 * These are simlar to "Apps" in some ways, but where a system doesn't NEED a front-end.  They are a space injected with RESOURCES and the INSTALLER can do whatever it wants with it.
 * 
 */
export class Hessia2Plugin extends CorePluginClass {

  public static details = {
    name: "Hessia",
    description: "Hessia 2 Core Plugin",
    dependencies: [HaliaComponentPlugin.details.id],
    id: "hessia2"
  }

  //  DONE!:  Get a NEW token when we are configured for cloud!
  //  DONE!:  Do NOT store S3 Config in storage unless it's custom!
  //  DONE!!!:  Use Amplify when we are configured as authenticated so we can get the new tokens!
  //  TODO:  Scope local config to USER (either Guest or Authenticated)
  //  TODO:  IF we are logged in with Cognito, THEN we should use THOSE credentials instead of the default set!!!


  public registerContext = (Context: React.Context<any>, initialState: any) => {
    this.registerHOC(({ children }) => (
      <Context.Provider value={initialState}>
        {children}
      </Context.Provider>
    ))
  };

  public registerHOC = (wrapper: any) => {
    registerHOC("hessia2", wrapper);
  }

  public removeHOC = (wrapper: any) => {
    removeHOC("hessia2", wrapper);
  }

  public install = async (program: any, { haliaComponentPlugin }: { haliaComponentPlugin: HaliaComponentPlugin }) => {
    registerHOCRegister("hessia2");
    haliaComponentPlugin.registerChildComponent(() => <HessiaProvider hessia2Plugin={this} />);
    return this;
  }
}



